import { Component, Inject, Input, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';

import { AuthService, LoginInfo } from 'src/app/shared/auth.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  tabIndex=0;
  minLength = 8;
  loading = false;
  submitted = false;
  failedLogin = false;
  loginFrom: FormGroup = this.fb.group({
    email: new FormControl('', [Validators.required, Validators.email]),
    password: new FormControl('', [
      Validators.required,
      Validators.minLength(this.minLength),
    ]),
  });

  constructor(
    private fb: FormBuilder,
    public dialogRef: MatDialogRef<LoginComponent>,
    private authService: AuthService,
    @Inject(MAT_DIALOG_DATA) public data: number
    ) { }

    ngOnInit() {
        //Called after the constructor, initializing input properties, and the first call to ngOnChanges.
    //Add 'implements OnInit' to the class.
    this.tabIndex = this.data ? this.data : 0;

  }

  closeDialog(): void {
    this.dialogRef.close();
  }
  get f() {
    return this.loginFrom.controls;
  }

  /**
   * Login Form Login clicked, invoke auth service to authenticate
   */
  onSubmit(): void {
    this.submitted = true;
    this.failedLogin = false;

    // stop here if form is invalid
    if (this.loginFrom.invalid) {
      return;
    }
    const authInfo: LoginInfo = {
      email: this.f.email.value,
      pwd: this.f.password.value,
    };

    this.authService.login(authInfo).subscribe((loginStatus: string): void => {
      if (loginStatus === this.authService.SUCCESS_LOGIN) {
        this.failedLogin = false;
        console.log('Successfully LoggedIn!');
      } else {
        this.failedLogin = true;
        console.log('Login failed!');
        return;
      }
      this.dialogRef.close(true);
    });
  }
}
