import { SelectionModel } from '@angular/cdk/collections';
import { AfterViewInit, Component, ViewChild } from '@angular/core';
import { FormControl} from '@angular/forms';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MatDialog } from '@angular/material/dialog';
import { CreateDialogComponent } from './create-dialog/create-dialog.component';
import { SparkDetailDirective } from './spark-detail/spark-detail.directive';
import { FESparkEntry} from './spark.model';
import { SparkService } from './spark.service';

@Component({
  selector: 'app-sparks',
  templateUrl: './sparks.component.html',
  styleUrls: ['./sparks.component.scss'],
})
export class SparksComponent implements AfterViewInit {
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(SparkDetailDirective, { static: true })

  newUser = false;
  displayedColumns: string[] = [
    'id',
    'service',
    'serviceUrls',
    'actions'
  ];
  allSparkDataSource: MatTableDataSource<FESparkEntry> = new MatTableDataSource<FESparkEntry>([]);
  selection = new SelectionModel<FESparkEntry>(true, []);
  appSparkDetail: SparkDetailDirective;
  serviceFilter = new FormControl('');

  constructor(private sparkService: SparkService, public dialog: MatDialog) {
    this.allSparkDataSource.filterPredicate =
        (row: FESparkEntry, filter: string) => JSON.stringify(row).toLocaleLowerCase().includes(filter);
  }

  onRowClicked(row: FESparkEntry): void {
    console.log(row);
  }

  applyFilter(event: Event): void {
    const filterValue = (event.target as HTMLInputElement).value;
    this.allSparkDataSource.filter = filterValue.trim().toLowerCase();
  }
  openCreateDialog(): void {
    console.log('clicked');
    this.dialog.open(CreateDialogComponent);
  }

  ngAfterViewInit(): void {
    this.allSparkDataSource.sort = this.sort;
    this.loadSparks();
  }
  loadSparks(): void {
    this.sparkService.getAllSparks().subscribe(
      sparks => {
        this.allSparkDataSource.data = sparks;
      },
      err => console.log(err),
      ()=> console.log('done'));
  }
}
