import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HttpClientXsrfModule } from '@angular/common/http';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { FlexLayoutModule } from '@angular/flex-layout';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SparksComponent } from './pages/sparks/sparks.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LbarComponent } from './pages/sparks/lbar/lbar.component';
import { HeaderComponent } from './atoms/header/header.component';
import { HomeComponent } from './pages/home/home.component';
import { CreateDialogComponent } from './pages/sparks/create-dialog/create-dialog.component';
import { TransformerComponent } from './pages/transformer/transformer.component';
import { AccountComponent } from './pages/account/account.component';
import { MaterialModule } from './material/material.module';
import { SparkDetailComponent } from './pages/sparks/spark-detail/spark-detail.component';
import { SparkDetailDirective } from './pages/sparks/spark-detail/spark-detail.directive';
import { SparkDetailRowComponent } from './pages/sparks/spark-detail/spark-detail-row.component';
import { SignupComponent } from './atoms/signup/signup.component';
import { LoginComponent } from './atoms/login/login.component';
import { httpInterceptorProviders } from './shared/interceptors';
import { fakeBackendProvider } from 'src/infra/backend';
import { ThemeToggleComponent } from './atoms/header/theme-toggle/theme-toggle.component';
import { STORAGE_PROVIDERS, WindowToken, windowProvider } from './shared/storage.service';

@NgModule({
  declarations: [
    AppComponent,
    SparksComponent,
    LbarComponent,
    HeaderComponent,
    TransformerComponent,
    AccountComponent,
    HomeComponent,
    CreateDialogComponent,
    SparkDetailComponent,
    SparkDetailDirective,
    SparkDetailRowComponent,
    SignupComponent,
    LoginComponent,
    ThemeToggleComponent,
  ],
  imports: [
    AppRoutingModule,
    BrowserModule,
    BrowserAnimationsModule,
    ClipboardModule,
    CommonModule,
    FlexLayoutModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    HttpClientXsrfModule.withOptions({
      cookieName: 'XSRF-TOKEN-COOKIE',
      headerName: 'CsrfHeaderToken',
    }),
    MaterialModule,
  ],
  providers: [httpInterceptorProviders, fakeBackendProvider, STORAGE_PROVIDERS,
        { provide: WindowToken, useFactory: windowProvider },

  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
