import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { SparkService } from '../spark.service';

@Component({
  selector: 'app-create-dialog',
  templateUrl: './create-dialog.component.html',
  styleUrls: ['./create-dialog.component.scss'],
})
export class CreateDialogComponent implements OnInit {
  @Input() siteUrl = '';
  @Output() createdSparkEvent = new EventEmitter<string>();

  createSparkForm: FormGroup;

  constructor(private fb: FormBuilder, private sparkService: SparkService) {}

  ngOnInit(): void {
    this.createSparkForm = this.fb.group({
      serviceUrl: new FormControl(this.siteUrl),
      account: new FormControl(),
    });
  }
  onSubmit(): void {
    this.sparkService
      .createSpark(this.createSparkForm.value)
      .subscribe((response) => {
        console.log('response:', response);
        this.createdSparkEvent.emit(response);
      });
  }
}
