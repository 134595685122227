<div class="viewport-container">
    <app-lbar></app-lbar>
    <div class="content">
        <h1 class="text-center">Edit User</h1>
        <form [formGroup]="form" (ngSubmit)="onSubmit()">
            <div class="form-row">
                <div class="form-group col">
                    <label for="firstName">First Name</label>
                    <input type="text" formControlName="firstName" class="form-control"
                        [ngClass]="{ 'is-invalid': submitted && f.firstName.errors }" />
                    <div *ngIf="submitted && f.firstName.errors" class="invalid-feedback">
                        <div *ngIf="f.firstName.errors.required">First Name is required</div>
                    </div>
                </div>
                <div class="form-group col">
                    <label for="lastName">Last Name</label>
                    <input type="text" formControlName="lastName" class="form-control"
                        [ngClass]="{ 'is-invalid': submitted && f.lastName.errors }" />
                    <div *ngIf="submitted && f.lastName.errors" class="invalid-feedback">
                        <div *ngIf="f.lastName.errors.required">Last Name is required</div>
                    </div>
                </div>
            </div>
            <div class="form-row">
                <div class="form-group col">
                    <label for="username">Username</label>
                    <input type="text" formControlName="username" class="form-control"
                        [ngClass]="{ 'is-invalid': submitted && f.username.errors }" />
                    <div *ngIf="submitted && f.username.errors" class="invalid-feedback">
                        <div *ngIf="f.username.errors.required">Username is required</div>
                    </div>
                </div>
                <div class="form-group col">
                    <label for="password">
                        Password
                        <em>(Leave blank to keep the same password)</em>
                    </label>
                    <input type="password" autocomplete="off" formControlName="password" class="form-control"
                        [ngClass]="{ 'is-invalid': submitted && f.password.errors }" />
                    <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
                        <div *ngIf="f.password.errors.required">Password is required</div>
                        <div *ngIf="f.password.errors.minlength">Password must be at least 6 characters</div>
                    </div>
                </div>
            </div>
            <div class="form-group">
                <button [disabled]="loading" class="btn btn-primary">
                    <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
                    Save
                </button>
                <a routerLink="/home" class="btn btn-link">Cancel</a>
            </div>
        </form>
    </div>
</div>