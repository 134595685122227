import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Observable, throwError, of,from, Subscription } from 'rxjs';
import { catchError, map, mergeMap, switchMap } from 'rxjs/operators';
import { Person } from './person.service';
import { Router } from '@angular/router';

export interface LoginInfo {
  email: string;
  pwd: string;
}

export interface Account {
  id: number;
  name: string;
  email: string;
}

/**
 * AuthService works in corcert with the authentication API
 * [OnPageLoad] - header.component.ts calls AuthService.load()
 *    [load()] Attepmts to load the user using the existing token (redis lookup).
 *        Server sets CSRF at this stage
 */
@Injectable({
  providedIn: 'root'
})
export class AuthService {
  get SUCCESS_LOGIN(): string { return 'SuccessfulLogin'; }
  get FAILED_LOGIN(): string { return 'FailedLogin'; }
  get NOT_LOGGEDIN(): string { return 'NotLoggedIn'; }


  debug = true;  // Log all requests in interceptor
  demo = false;  // filler Sparks Values

  // API responds with CSRF Token and follow on load request uses it.
  loadAccountUrl = 'be/auth/load ';

  // TODO: move to a session manager interceptor
  currentUser = 'fbeuler@i8.com';
  personUrl = 'be/person';
  loginUrl = 'be/auth/user-login';
  logoutUrl = 'be/auth/user-logout';

  cSaltUrl = 'be/auth/csalt';  // POST request to enable CSRF
  csrftoken = '';
  isLoggedIn = false;
  loggedInUser: Account;

  constructor(private http: HttpClient, private router: Router) {}

  public static hex(letters: ArrayBuffer) {
    const lettersArray = Array.from(new Uint8Array(letters));
    return lettersArray.map((b) => b.toString(16).padStart(2, '0')).join(''); // convert bytes to hex string
  }
  /**
   * Load the user if authenticated, BE figures using stored token.
   */
  loadAccount(): Subscription {
    return this.http.get(this.loadAccountUrl).pipe(catchError(this.userNotAuthenticated))
      .subscribe(account => {
        if (account) {
          this.loggedInUser = account;
          this.isLoggedIn = true;
        } else {
          this.isLoggedIn = false;
          this.loggedInUser = undefined;
        }
      });
  }

  /**
   * The authentication process captures a client salt, hashes and requests login
   *    Clear text password is never stored on server or transmitted.
   *
   * @param LoginInfo login: username and password dict.
   * @returns token and response sets correct cookies.
   */
  login(loginInfo: LoginInfo): Observable<string> {
    const email = loginInfo.email;
    return this.http.post(this.cSaltUrl, { email }, { responseType: 'text'})
      .pipe(
        mergeMap(
          (cSalt: string) => {
            const encodedInput = new TextEncoder().encode(loginInfo.pwd + cSalt);
            return crypto.subtle.digest('SHA-256', encodedInput);
          }),
        mergeMap(pwdDigest => {
          // Hexify
          loginInfo.pwd = Array.from(new Uint8Array(pwdDigest))
            .map(b => b.toString(16).padStart(2, '0')).join('');
          return this.http.post(this.loginUrl, loginInfo, { responseType: 'text'})
            .pipe(catchError(this.handleError))
            .pipe(map(response => {
              this.isLoggedIn = true;
              return this.SUCCESS_LOGIN;
            }));
        })
      );
    // return of(this.FAILED_LOGIN);
  }

  logout(): void {
    this.http.post(this.logoutUrl, { responseType: 'text' })
      .subscribe(resp => {
        this.isLoggedIn = false;
        console.log(resp);
      });
  }

  getAccount(): Observable<string> {
    return this.http.get(this.loadAccountUrl, {responseType: 'text'}).pipe(catchError(this.handleError));
  }


  private userNotAuthenticated(error: HttpErrorResponse): Observable<any> {
    if (error.status === 401) {
      // A client-side or network error occurred. Handle it accordingly.
      console.log('The user is not logged in');
      this.isLoggedIn = false;
      return of(this.NOT_LOGGEDIN);
    } else {
        console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`);
          return throwError(
      'Something bad happened; please try again later.');
    }
  }

  private handleError(error: HttpErrorResponse): Observable<any>  {
    if (error.status === 401) {
      // A client-side or network error occurred. Handle it accordingly.
      this.isLoggedIn = false;
      console.error('Unauthorized error occurred:', error.message);
      this.router.navigateByUrl('#error').then(()=> console.log('routed to login'));
      return of(this.FAILED_LOGIN);
    }
    else if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong.
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`);
    }
    // Return an observable with a user-facing error message.
    return throwError(
      'Something bad happened; please try again later.');
  }
}
