<mat-tab-group [selectedIndex]="tabIndex" mat-align-tabs="center" animationDuration="500ms">
    <mat-tab label="Login">
        <div class="login-dialog" fxLayout="column" fxLayoutAlign="center center">
            <h1 mat-dialog-title fxLayoutAlign="center center"> Lets get you logged in!</h1>

            <form fxLayout="column" fxLayoutAlign="center center" fxFlex="grow" [formGroup]="loginFrom"
                (ngSubmit)="onSubmit()">
                <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
                    <div *ngIf="f.email.errors.required">Email is required</div>
                    <div *ngIf="f.email.errors.email">Valid Email is required</div>
                </div>
                <mat-form-field id="signup-form" appearance="outline">
                    <mat-label for="email">Email</mat-label>
                    <input matInput type="text" formControlName="email"
                        [ngClass]="{ 'is-invalid': submitted && f.email.errors }" />
                    <mat-icon matSuffix>mail</mat-icon>
                </mat-form-field>

                <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
                    <div *ngIf="f.password.errors.required">Password is required</div>
                    <div *ngIf="f.password.errors.minlength">Password must be at least {{minLength}} characters</div>
                </div>
                <mat-form-field class="signup-form" appearance="outline">
                    <mat-label for="password">Password</mat-label>
                    <input matInput type="password" formControlName="password"
                        [ngClass]="{ 'is-invalid': submitted && f.password.errors }" autocomplete="current-password" />
                    <mat-icon matSuffix>vpn_key</mat-icon>
                </mat-form-field>
                <div *ngIf="failedLogin" class="invalid-feedback">Login Failed! Email or Password is incorrect.</div>
                <div fxLayoutAlign="center center" fxLayoutGap="1rem">
                    <button type="submit" mat-button color="accent">Login</button>
                    <button type="button" mat-button (click)="closeDialog()">Cancel</button>
                </div>
            </form>
        </div>
    </mat-tab>
    <mat-tab label="Register">
        <app-signup></app-signup>
    </mat-tab>

</mat-tab-group>