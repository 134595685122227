<div class="page" fxFlexFill fxLayout="column" fxFlexAlign="center center">
    <mat-toolbar [class.mat-elevation-z2]="!transparentMode" onscroll="myScroll" matRipple [ngClass]="classList"
        fxLayout fxLoutAlign="center center">
        <div class="header-content" fxLayout fxLayoutAlign="space-between center">
            <!-- <button mat-icon-button aria-label="Menu icon-button for left bar">
                <mat-icon>menu</mat-icon>
            </button> -->
            <mat-nav-list class="left-side">
                <a class="logo-wrapper" href="#/home">
                    <img class="logo" *ngIf="!transparentMode" src="../../../assets/AllSpark-Logo-dark.png" />
                    <img class="logo" *ngIf="transparentMode" src="../../../assets/AllSpark-Logo-white.png" />
                </a>
            </mat-nav-list>
            <div class="right-side" fxLayoutGap="20px" fxLayout fxLayoutAlign="pace-between center">
                <a id="automation-nav" href="https://allspark.substack.com/">Blog</a>
                <a id="automation-nav" href="#/transformer">Transformer</a>
                <a *ngIf="isLoggedIn" id="sparks-nav" href="#/sparks">Sparks</a>
                <app-theme-toggle></app-theme-toggle>
                <button *ngIf="!isLoggedIn" mat-raised-button color="accent" (click)="openLoginDialog()">Login</button>
                <button *ngIf="isLoggedIn" mat-button [matMenuTriggerFor]="menu">
                    <mat-icon>account_circle</mat-icon>
                </button>
                <mat-menu #menu="matMenu">
                    <a mat-menu-item href="#/transformer"> Transformer </a>
                    <a mat-menu-item href="#/sparks"> Settings </a>
                    <a mat-menu-item href="mailto:allspark@innov8.software?subject=Information%20Request">Contact US
                    </a>
                    <button type="button" mat-menu-item (click)="onLogoutClick()">Log out</button>
                </mat-menu>

            </div>
        </div>
    </mat-toolbar>
    <ng-content></ng-content>
    <footer fxLayout fxLayoutAlign="center center">
        <p>All rights reserved to Innov8 Software&nbsp;</p>
        <svg id="twitter-logo" data-name="Logo" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 400 400">
            <path
                d="M153.62,301.59c94.34,0,145.94-78.16,145.94-145.94,0-2.22,0-4.43-.15-6.63A104.36,104.36,0,0,0,325,122.47a102.38,102.38,0,0,1-29.46,8.07,51.47,51.47,0,0,0,22.55-28.37,102.79,102.79,0,0,1-32.57,12.45,51.34,51.34,0,0,0-87.41,46.78A145.62,145.62,0,0,1,92.4,107.81a51.33,51.33,0,0,0,15.88,68.47A50.91,50.91,0,0,1,85,169.86c0,.21,0,.43,0,.65a51.31,51.31,0,0,0,41.15,50.28,51.21,51.21,0,0,1-23.16.88,51.35,51.35,0,0,0,47.92,35.62,102.92,102.92,0,0,1-63.7,22A104.41,104.41,0,0,1,75,278.55a145.21,145.21,0,0,0,78.62,23"
                fill="black" />
        </svg>
    </footer>
</div>