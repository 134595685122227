<div class="home-content">
    <div class="intro-bkg-wrapper"></div>
    <div class="promotion">
        <div class="headline">
            <mat-card class="mat-card-flat">
                <mat-card-title>
                    <h1>Manage your account sprawl!</h1>
                </mat-card-title>
                <mat-card-subtitle>"By 2025 the average user manages 250+ accounts"</mat-card-subtitle>
                <mat-card-content>
                    <ul>
                        <li>
                            <p>The first vault less password manager that works by
                                transpiling memorable passwords into login passwords</p>
                        </li>
                        <li>
                            <p> Available in all platforms across Android, PC,
                                Mac, iOS, Chrome, Safari and Edge browsers</p>
                        </li>
                        <li>
                            <p>Metadata on websites so your Autofill never misses</p>
                        </li>
                        <li>
                            <p>New personalized feed and bulk edit tools</p>
                        </li>
                    </ul>
                </mat-card-content>
                <mat-card-actions>
                    <button mat-raised-button color="accent" (click)="openDialog()">Sign Up!</button>
                    <a mat-raised-button color="accent" target="_blank" href="https://allspark.substack.com">Learn
                        More</a>
                </mat-card-actions>
            </mat-card>
        </div>
    </div>
    <section class="categories">
        <div class="feature-icons">
            <mat-card class="icon-wrapper">
                <mat-icon color="primary">ac_unit</mat-icon>
                <mat-divider></mat-divider>
                <p>Unique Salts per product</p>

            </mat-card>
            <mat-card class="icon-wrapper">
                <mat-icon color="primary">lock</mat-icon>
                <mat-divider></mat-divider>

                <p>Crytographically secure hashes</p>
            </mat-card>
            <mat-card class="icon-wrapper">
                <mat-icon color="primary">sync</mat-icon>
                <mat-divider></mat-divider>

                <p>Sync salts across devices</p>
            </mat-card>
        </div>
    </section>
    <section class="service">
        <div class="informational" fxLayout="row" fxLayoutAlign="space-around center">
            <img fxFlex="50%" src="assets/happy-screen.svg">
            <mat-card class="mat-card-flat" fxFlex="40%">
                <mat-card-title>Master Plan</mat-card-title>
                <mat-card-subtitle> Internet is a factured set of products </mat-card-subtitle>
                <mat-card-content>
                    <p> A seasoned group of Silicon valley, MIT and Harvard graduates
                        have decided to tackle one of the most flawed parts of the fractured internet, namely the
                        management of accounts and credentials for access. By leveraging a combination of SSO and
                        Password manager
                        our team aims to alleviate these challenges
                </mat-card-content>
            </mat-card>
        </div>
        <div class="informational" fxLayout="row" fxLayoutAlign="space-around center">
            <mat-card class="mat-card-flat" fxFlex="30%">
                <mat-card-title> Fractured Accounts</mat-card-title>
                <mat-card-content> Similar to cities that hyperscaled without central planning the internet
                    is also a fractured set of services that do not reuse data, communicate across silos, or
                    allow the user to be able to modify their personal data across a lot of web sites.
                </mat-card-content>
            </mat-card>
            <img src="assets/chaos-accounts.svg" fxFlex="50%">
        </div>
        <div class="informational" fxLayout="row" fxLayoutAlign="space-around center">
            <img src="assets/clean-accounts.svg" fxFlex="50%">
            <mat-card class="mat-card-flat" fxFlex="40%">
                <mat-card-title> User at the Center</mat-card-title>
                <mat-card-content>
                    Internet content consumptions should be structured with the user in mind and sotware
                    services consuming the customers identity, personal data and billing information. The
                    platform intern allows users to manage access and mass update accounts.
                </mat-card-content>
            </mat-card>
        </div>
    </section>
    <section class="curr-events">
        <div class="news-area">
            <h1 class="mat-h1"> Latest news </h1>
            <mat-card>
                <mat-card-title> Frontend Updates</mat-card-title>
                <mat-card-subtitle>02/13/2022 </mat-card-subtitle>
                <mat-card-content>
                    A full feature re-design of the mobile application is completed, the new mobile experience
                    blends the personal feed, automation and password components seamlessly into one application.
                    <br><br>
                    The home page and header have also recieved some small modifications in accordance with the
                    expected front end overhaul for the web experience. A new subsack will also replace this section in
                    the near future: <a href="https://allspark.substack.com/">Subscibe here</a>

                </mat-card-content>
            </mat-card>
            <mat-card>
                <mat-card-title> Website Feature Complete</mat-card-title>
                <mat-card-subtitle>08/19/2021 </mat-card-subtitle>
                <mat-card-content>
                    The end to end functionality has been built out and is only waiting for cloud deployment to be
                    tested
                    by close friends. In order to demonstrate the hash-based password management a full feature MVP
                    was necessary to gather for a trustable signal.
                    <br>
                    The first phase of usage will highlight Ghost Mode operation, where anyone can use AllSpark without
                    creating an acccount. The idea is to encourage minimal data usage and prove out the value of
                    a password management syste that does not use a vault.
                    <br>
                    However, the simpleset usage will be to utilize an account with AllSpark to synchronize website
                    specfic
                    seeds across all your devices. Whenever a service is onboarded with AllSpark, a new seed is created
                    for
                    the user. In GhostMode this is replaced by a default seed across all your sites, or personal
                    managment
                    of the seed data normall managed by AllSpark.
                </mat-card-content>
            </mat-card>
            <mat-card>
                <mat-card-title> React Native MVP Built!</mat-card-title>
                <mat-card-subtitle>07/28/221 </mat-card-subtitle>
                <mat-card-content>
                    Password management without mobile tools is hardly a solution. As such a react-native application
                    has
                    been
                    built but currently only supports Ghost mode. Full integration with loading seeds will be built out
                    in
                    short order.
                </mat-card-content>
            </mat-card>
            <mat-card>
                <mat-card-title> Functioning Chrome Extension Built!</mat-card-title>
                <mat-card-subtitle>07/16/221 </mat-card-subtitle>
                <mat-card-content>
                    Once most of the backend, authentication, and FE based transformation were built out. The next set
                    of
                    work is to build out client solutions. Today, the chrome extension has been completed to support
                    right click & transform flows, the website transformer has also been implemented as pop up for users
                    that do not want to allow content script access.

                    Omnibox experience has started development to simply load a page with your username pre-filled.
                    Further
                    pushing the envelope of deligghting our users with speedy authentication experiences.
                </mat-card-content>
            </mat-card>
            <mat-card>
                <mat-card-title>Home Page Launched</mat-card-title>
                <mat-card-subtitle>11/11/2020 </mat-card-subtitle>
                <mat-card-content>
                    The first iteration of our project page is launched and live.
                </mat-card-content>
            </mat-card>
        </div>
    </section>
</div>