<div class="signup-dialog" fxLayout="column" fxLayoutAlign="center center">
    <h1 mat-dialog-title> Lets get you signed up!</h1>
    <form [formGroup]="registerForm" (ngSubmit)="onSubmit()" fxLayout="column" fxLayoutAlign="center center">
        <mat-form-field class="signup-form">
            <mat-label for="firstName">First Name</mat-label>
            <input matInput type="text" formControlName="firstName" />
            <div *ngIf="submitted && f.firstName.errors" class="invalid-feedback">
                <div *ngIf="f.firstName.errors.required">First Name is required</div>
            </div>
        </mat-form-field>
        <mat-form-field class="signup-form">
            <mat-label for="lastName">Last Name</mat-label>
            <input matInput type="text" formControlName="lastName" />
            <div *ngIf="submitted && f.lastName.errors" class="invalid-feedback">
                <div *ngIf="f.lastName.errors.required">Last Name is required</div>
            </div>
        </mat-form-field>
        <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
            <div *ngIf="f.email.errors.required">Email is required</div>
            <div *ngIf="f.email.errors.email">Valid Email is required</div>
            <div *ngIf="f.email.errors.notUnique">An account aready exists for this email</div>
        </div>

        <mat-form-field id="signup-form" appearance="outline">
            <mat-label for="email">Email</mat-label>
            <input matInput type="text" formControlName="email"
                [ngClass]="{ 'is-invalid': submitted && f.email.errors }" />
            <mat-icon matSuffix>mail</mat-icon>
        </mat-form-field>

        <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
            <div *ngIf="f.password.errors.required">Password is required</div>
            <div *ngIf="f.password.errors.minlength">Password must be at least {{minLength}} characters</div>
        </div>
        <mat-form-field class="signup-form" appearance="outline">
            <mat-label for="password">Password</mat-label>
            <input matInput type="password" formControlName="password"
                [ngClass]="{ 'is-invalid': submitted && f.password.errors }" />
            <mat-icon matSuffix>vpn_key</mat-icon>
        </mat-form-field>
        <!-- <mat-form-field class="signup-form" appearance="standard" id="salt-form-field">
        <mat-label for="cSalt">Generated Client Salt</mat-label>
        <input matInput formControlName="cSalt" />
        <button tabindex="-1" mat-button matSuffix aria-label="Client Salt"
            matTooltip="The password above is hashed before sending it to the server">
            <mat-icon>help</mat-icon>
        </button>
    </mat-form-field>
    <mat-form-field class="signup-form" appearance="standard" id="salt-form-field">
        <mat-label for="nonce">Generated Personal Salt</mat-label>
        <input matInput formControlName="nonce" />
        <button tabindex="-1" mat-button matSuffix aria-label="Top level Salt"
            matTooltip="The top level seed listed here is used in addition to the company secret for seeding your future hashes">
            <mat-icon>help</mat-icon>
        </button>
    </mat-form-field> -->
        <div fxLayoutAlign="center center" fxLayoutGap="1rem">
            <!-- <button mat-button (click)="onDebug()">Debug</button> -->
            <button type="submit" mat-button color="accent">Register</button>
            <button mat-button (click)="closeDialog()">Cancel</button>
        </div>

    </form>
</div>