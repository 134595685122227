import { Component, OnInit, Input, HostListener, ViewChild, AfterContentChecked } from '@angular/core';
import { MatRipple } from '@angular/material/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/shared/auth.service';
import { LoginComponent } from '../login/login.component';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, AfterContentChecked {
  @ViewChild(MatRipple) ripple: MatRipple;
  transparentMode = true;
  classList = [''];

  get isHomePage() {
    return document.URL.search('home') !== -1;
  }
  get isLoggedIn() {
    return this.authService.isLoggedIn;
  }

  constructor(private dialog: MatDialog, private authService: AuthService, private router: Router) { }

  @HostListener('window:scroll', ['$event.target']) // for window scroll events
  onScroll(e) {
    const scroll = e.scrollingElement.scrollTop;
    console.log('This is the scroll position', scroll);
    if (scroll > 580 && scroll < 640 && this.isHomePage) {
      console.log('inverted');
      this.launchRipple();
      this.classList = [''];
    } else if (this.isHomePage && scroll < 580) {
      this.classList = ['transparent'];
      this.transparentMode = true;
    } else {
      this.classList = [''];
      this.transparentMode = false;

    }
  }
  /** Reference to the directive instance of the ripple. */
  goHome() {
    this.router.navigateByUrl('/home');
  }

  launchRipple() {
    const rippleRef = this.ripple.launch({
      persistent: true,
      centered: true
    });

    // Fade out the ripple later.
    rippleRef.fadeOut();
  }

  ngOnInit(): void {
    this.authService.loadAccount();
  }
  ngAfterContentChecked(): void{
    if (this.isHomePage && window.pageYOffset < 640) {
    this.transparentMode = true;
    this.classList = ['transparent'];
  } else {
    this.transparentMode = false;
    this.classList = [''];
  }

  }
  openSignupDialog(): void {
    const dialogRef = this.dialog.open(LoginComponent, {data: 2});
    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
      console.log(result); // Pizza!
    });
  }

  openLoginDialog(): void {
    const dialogRef = this.dialog.open(LoginComponent);
    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
      console.log(result); // Pizza!
      if (result === true) {
        this.authService.isLoggedIn = true;
      }
    });
  }

  onLogoutClick(): void {
    this.authService.logout();
  }
  onSettingsClick(): void {

  }
}
