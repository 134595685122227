import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { TransformerComponent } from './pages/transformer/transformer.component';
import { SparksComponent } from './pages/sparks/sparks.component';
import { AccountComponent } from './pages/account/account.component';
import { HomeComponent } from './pages/home/home.component';

const routes: Routes = [
  { path: '', pathMatch: 'full', redirectTo: 'home' },
  { path: 'login', component: HomeComponent },
  { path: 'home', component: HomeComponent },
  { path: 'account', component: AccountComponent },
  { path: 'app', component: TransformerComponent },
  { path: 'transformer', component: TransformerComponent },
  { path: 'sparks', component: SparksComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true, relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
