import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Observable, throwError, from } from 'rxjs';
import { catchError, mergeMap, retry } from 'rxjs/operators';
import { AuthService } from './auth.service';
import { SparkService } from '../pages/sparks/spark.service';

export interface Person {
  id: number;
  firstName: string;
  lastName: string;
  email: string;
  cSalt: string;
  password: string;
}

@Injectable({
  providedIn: 'root'
})
export class PersonService {
  personUrl = 'be/person';
  // personUrl = 'fake/person';

  constructor(private http: HttpClient, private authService: AuthService) { }

  getAll(): Observable<any> {
    return this.http.get<Person[]>(`${this.personUrl}`);
  }

  /**
   * Method to fetch a person by their id
   *
   * @param id unique id for person
   *   .subscribe(resp => {
   *      const keys = resp.headers.keys();
   *      this.headers = keys.map(key =>
   *         `${key}: ${resp.headers.get(key)}`);
   *      response = { ... resp.body };
   */

  getByEmail(email: string): Observable<HttpResponse<Person>> {
    return this.http.get<Person>(
      `${this.personUrl}/` + email,
      { observe: 'response' }
    ).pipe(catchError(this.handleError));
  }

  /**
   * User registration endpoint with support for client side password hashing
   *
   * @param person Person: user to create
   * @returns
   */
  singup(person: Person): Observable<string> {
    person.cSalt = this.userRandomSalt(10);
    const encodedInput = new TextEncoder().encode(person.password + person.cSalt);
    return from(crypto.subtle.digest('SHA-256', encodedInput)).pipe(mergeMap(
      clientHashedPasswordDigest => {
        const clientHashedPassword = Array.from(new Uint8Array(clientHashedPasswordDigest))
          .map(b => b.toString(16).padStart(2, '0')).join('');
        person.password = clientHashedPassword;
        console.log('Launching request: ' + person);
        // TODO: loading spinner and error
        return this.singupRequest(person).pipe(catchError(this.handleSignupError));
      }));
  }

  update(person): Observable<any> {
    return this.http.put(`${this.personUrl}/` + person.id, person).pipe(catchError(this.handleError));
  }

  delete(id: number): Observable<any> {
    return this.http.delete(`${this.personUrl}/` + id).pipe(catchError(this.handleError));
  }

    // NON JSON Responses
  private singupRequest(person: Person): Observable<string> {
      return this.http.post(`${this.personUrl}`, person, {responseType:'text'})
        .pipe(catchError(this.handleError));
  }

  private userRandomSalt(size=32): string {
      return AuthService.hex(crypto.getRandomValues(new Uint8Array(size)));
    }

  private handleSignupError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong.
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`);
    }
    // Return an observable with a user-facing error message.
    // TODO: More granular error handling from wireline reply.
    return throwError(error.error);
  }
  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong.
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`);
    }
    // Return an observable with a user-facing error message.
    return throwError(error.error);
  }
}
