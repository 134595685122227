import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/shared/auth.service';
import { PersonService } from 'src/app/shared/person.service';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';

@Component({
  selector: 'app-account',
  templateUrl: './account.component.html',
  styleUrls: ['./account.component.scss']
})
export class AccountComponent implements OnInit {
  content: string;

  form: FormGroup;
  isAddMode: boolean;
  loading = false;
  submitted = false;

  get f() { return this.form.controls; }


  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private authService: AuthService,
    private personService: PersonService) { }

  ngOnInit(): void {
    // password not required in edit mode
    const passwordValidators = [Validators.minLength(6)];
    if (this.isAddMode) {
      passwordValidators.push(Validators.required);
    }

    this.form = this.formBuilder.group({
      id: ['', Validators.required],
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      username: ['', Validators.required],
      password: ['', passwordValidators]
    });

    this.authService.getAccount().subscribe(resp => {
      this.content = resp;
      const respJson = JSON.parse(resp);
      const name = respJson.name;
      this.f.id.setValue(respJson.id);
      this.f.firstName.setValue(name.split(' ')[0]);
      this.f.lastName.setValue(name.split(' ')[1]);
      this.f.username.setValue(respJson.email);
    });

  }


  onSubmit() {
    this.submitted = true;
    // stop here if form is invalid
    if (this.form.invalid) {
      return;
    }
    this.loading = true;
    console.log('Success');
    this.personService.update(this.form.value).subscribe(
      resp=> {
        resp = JSON.parse(resp);
        console.log(resp.msg);
        this.loading = false;
        alert(resp.msg);
      },
      err=> {
        alert('Update failed - Please try again');
        console.log(err);
        this.loading = false;
      }
    );
  }

}
