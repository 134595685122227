/* eslint-disable @typescript-eslint/naming-convention */
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of, throwError } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { AuthService } from 'src/app/shared/auth.service';
import { AccountEntry, FESparkEntry, ServiceEntry, SparkCreateRequest } from './spark.model';

@Injectable({
  providedIn: 'root',
})
export class SparkService {
  sparkUrl = '/be/salt';
  allSparksUrl = '/be/salts';

  serviceFillers: ServiceEntry[] = [
    {
      id: 1,
      serviceName: 'Google',
      serviceUrls: ['https://www.google.com'],
    },
    {
      id: 2,
      serviceName: 'yahoo',
      serviceUrls: ['https://www.yahoo.com'],
    },
    {
      id: 3,
      serviceName: 'facebook',
      serviceUrls: ['https://www.facebook.com'],
    },
  ];

  accountFillers: AccountEntry[] = [
    {
      id: 1,
      serviceEntryId: 1,
      account: 'foobar@i8.com',
      accountSalt: '4e8cb815c25088d616617c7b935f6355ff06a64e',
    },
    {
      id: 2,
      serviceEntryId: 1,
      account: 'catLover@i8.com',
      accountSalt: '7c314c8994d4f89900dd761a0a1cd4fad6471ead',
    },
    {
      id: 3,
      serviceEntryId: 1,
      account: '2rogue@i8.com',
      accountSalt: '4e8cb815c25088d616617c7b935f6355ff06a64e',
    },
    {
      id: 4,
      serviceEntryId: 2,
      account: 'catLover@i8.com',
      accountSalt: '7c314c8994d4f89900dd761a0a1cd4fad6471ead',
    },
    {
      id: 5,
      serviceEntryId: 2,
      account: 'foobar',
      accountSalt: '4e8cb815c25088d616617c7b935f6355ff06a64e',
    },
    {
      id: 6,
      serviceEntryId: 2,
      account: '2rogue@i8.com',
      accountSalt: '7c314c8994d4f89900dd761a0a1cd4fad6471ead',
    },
    {
      id: 7,
      serviceEntryId: 3,
      account: 'catLover@i8.com',
      accountSalt: '7c314c8994d4f89900dd761a0a1cd4fad6471ead',
    },
    {
      id: 8,
      serviceEntryId: 3,
      account: 'foobar@i8.com',
      accountSalt: '4e8cb815c25088d616617c7b935f6355ff06a64e',
    },
    {
      id: 9,
      serviceEntryId: 3,
      account: '2rogue',
      accountSalt: '7c314c8994d4f89900dd761a0a1cd4fad6471ead',
    },
    {
      id: 10,
      serviceEntryId: 1,
      account: 'foobar@i8.com',
      accountSalt: '4e8cb815c25088d616617c7b935f6355ff06a64e',
    },
    {
      id: 11,
      serviceEntryId: 1,
      account: 'foobar@i8.com',
      accountSalt: '4e8cb815c25088d616617c7b935f6355ff06a64e',
    },
    {
      id: 12,
      serviceEntryId: 1,
      account: 'catLover',
      accountSalt: '7c314c8994d4f89900dd761a0a1cd4fad6471ead',
    },
    {
      id: 13,
      serviceEntryId: 1,
      account: '2rogue@i8.com',
      accountSalt: '4e8cb815c25088d616617c7b935f6355ff06a64e',
    },
    {
      id: 14,
      serviceEntryId: 2,
      account: 'catLover@i8.com',
      accountSalt: '7c314c8994d4f89900dd761a0a1cd4fad6471ead',
    },
    {
      id: 15,
      serviceEntryId: 2,
      account: 'foobar@i8.com',
      accountSalt: '4e8cb815c25088d616617c7b935f6355ff06a64e',
    },
    {
      id: 16,
      serviceEntryId: 2,
      account: '2rogue@i8.com',
      accountSalt: '7c314c8994d4f89900dd761a0a1cd4fad6471ead',
    },
    {
      id: 17,
      serviceEntryId: 3,
      account: 'catLover@i8.com',
      accountSalt: '7c314c8994d4f89900dd761a0a1cd4fad6471ead',
    },
    {
      id: 18,
      serviceEntryId: 3,
      account: 'foobar@i8.com',
      accountSalt: '4e8cb815c25088d616617c7b935f6355ff06a64e',
    },
    {
      id: 19,
      serviceEntryId: 3,
      account: '2rogue@i8.com',
      accountSalt: '7c314c8994d4f89900dd761a0a1cd4fad6471ead',
    },
  ];
  constructor(private authService: AuthService,private http: HttpClient) {}

  /**
   * Reshape api calls to fit an array of services with user accounts.
   */
  getFillerSparks(): Observable<FESparkEntry[]> {
    const services: FESparkEntry[] = [];
    let tempService: FESparkEntry;
    this.serviceFillers.map(iservice => {
      tempService = {
        id: iservice.id,
        serviceName: iservice.serviceName,
        serviceUrls: iservice.serviceUrls,
        accounts: []
      };
      services.push(tempService);
    });
    this.accountFillers.forEach(account =>
      services[account.serviceEntryId - 1].accounts.push(account));
    return of(services);
  }

  getAccountSpark(serviceUrl: string): Observable<any> {
    return this.http.get(this.sparkUrl, {params: {serviceUrl}});
  }
  /**
   * Fetch sparks from backend shaped to match current UI requirements.
   */
  getAllSparks(): Observable<any> {
    if (this.authService.demo) {
      return this.getFillerSparks();
    }
    return this.http.get<any>(this.allSparksUrl).pipe(catchError(resp => of([])));
  }

  createSpark(newSpark: SparkCreateRequest): Observable<any> {
    console.log(newSpark);
    return this.http.post(this.sparkUrl, newSpark, {responseType:'text'})
      .pipe(catchError(this.handleError));
  }

/**
 * Subtle browser API operates on ArrayBuffers
 *
 * @param entropy
 * @param account
 * @param pvalue : Memorable value
 * @param algorithm
 */
async sparkIt(
  entropy: string,
  account: string,
  pvalue: string,
  algorithm: string = 'SHA-256'
) {
  console.log('Entropy: ' + entropy + '  Account: ' + account + '    pvalue: ' + pvalue);
  // TODO capture csalt and xor
  const encoder = new TextEncoder();
  const input1 = encoder.encode(entropy);
  const round1 = await crypto.subtle.digest(algorithm, input1);
  console.log(' 1 Hex - ' + AuthService.hex(round1));

  const input2 = new Uint8Array([...encoder.encode(account), ...new Uint8Array(round1)]);
  const round2 = await crypto.subtle.digest(algorithm, input2);
  console.log('Step 2 Hex - ' + AuthService.hex(round2));

  const input3 = new Uint8Array([...encoder.encode(pvalue), ...new Uint8Array(round2)]);
  const saltedP = await crypto.subtle.digest(algorithm, input3);
  console.log('Step 3 Hex - ' + AuthService.hex(saltedP));
  // Trim to 8-12 chars.
  const saltedPValue = new Uint8Array(saltedP);
  const pSize = 8 + (Math.abs(saltedPValue[0]) % 4);
  // Base64
  const b64PValue = btoa(String.fromCharCode(...new Uint8Array(saltedP)));
  let finalValue = b64PValue.substring(0, pSize);
  finalValue = this.addSpecialChar(finalValue);
  return finalValue;
}

private addSpecialChar(pValue) {
    // Add special character selected by the second word at selected index.
    // Special chars superset : !'#$%&'()*+,-./:;<=>?@[\]^_`{|}~
    // https://owasp.org/www-community/password-special-characters skipping: &@[]{}|~`;
    const specialChars = ['!', '+', '-', '@', '_', '$', '#', '?', '.'];
    // Selecting commonly used so that login pages do not block onboarding
    // https://xato.net/today-i-am-releasing-ten-million-passwords-b6278bbe7495#.VNojhPnF98E
    const genInt = pValue.charCodeAt(1);
    const specialChar = specialChars[genInt % specialChars.length];
    const insertIdx = genInt % pValue.length;
    pValue = pValue.replace('/', '');
    pValue = pValue.slice(0, insertIdx) + specialChar + pValue.slice(insertIdx);
    return pValue;
}


  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong.
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`);
    }
    // Return an observable with a user-facing error message.
    return throwError(
      'Something bad happened; please try again later.');
  }

}
