<h1 mat-title>Onboard a new account!</h1>
<form [formGroup]="createSparkForm" (ngSubmit)="onSubmit()">
    <div class="create-content">
        <mat-form-field appearance="fill">
            <mat-label>Enter the URL of the service</mat-label>
            <input matInput #serviceUrlInput formControlName="serviceUrl">
            <mat-icon matSuffix color="primary">website</mat-icon>
        </mat-form-field>
        <div class="created-service"></div>
        <div class="found-service"></div>
        <mat-form-field appearance="fill">
            <mat-label>Enter username for the account (optional)</mat-label>
            <input matInput #accountInput formControlName="account">
            <button mat-button matSuffix aria-label="Username"
                matTooltip="Saved usernames are autofilled by storing on the server">
                <mat-icon>help</mat-icon>
            </button>
        </mat-form-field>
    </div>
    <div>
        <button mat-button type="submit" position="right">Submit</button>
        <button mat-button position="right">Close</button>
    </div>
</form>