import { Injectable } from '@angular/core';
import { HttpRequest, HttpResponse, HttpHandler, HttpEvent, HttpInterceptor, HTTP_INTERCEPTORS } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { delay, materialize, dematerialize } from 'rxjs/operators';

// array in local storage for registered users
const usersKey = 'angular-10-registration-login-example-users';
let users = JSON.parse(localStorage.getItem(usersKey)) || [];

@Injectable()
export class FakeBackendInterceptor implements HttpInterceptor {
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const { url, method, headers, body } = request;

        const basicDetails =(user) => {
            const { id, username, firstName, lastName } = user;
            return { id, username, firstName, lastName };
        };
        // helper functions

        const ok = (bodyParam?: any)=>
             of(new HttpResponse({ status: 200, body:bodyParam }))
                .pipe(delay(500)) // delay observable to simulate server api call
        ;

        const error = (message) =>
        // call materialize and dematerialize to ensure delay even if an error is thrown
        // (https://github.com/Reactive-Extensions/RxJS/issues/648);
             throwError({ error: { message } })
                .pipe(materialize(), delay(500), dematerialize());

        const unauthorized =() => throwError({ status: 401, error: { message: 'Unauthorized' } })
                .pipe(materialize(), delay(500), dematerialize());


        const isLoggedIn =() => headers.get('Authorization') === 'Bearer fake-jwt-token';

        const idFromUrl =() => {
            const urlParts = url.split('/');
            return parseInt(urlParts[urlParts.length - 1], 10);
        };


        // route functions

        const authenticate = ()=> {
            const { username, password } = body;
            const user = users.find(x => x.username === username && x.password === password);
            if (!user) { return error('Username or password is incorrect');}
            return ok({
                ...basicDetails(user),
                token: 'fake-jwt-token'
            });
        };
        const register =() => {
            const user = body;

            if (users.find(x => x.username === user.username)) {
                return error('Username "' + user.username + '" is already taken');
            }

            user.id = users.length ? Math.max(...users.map(x => x.id)) + 1 : 1;
            users.push(user);
            localStorage.setItem(usersKey, JSON.stringify(users));
            return ok();
        };


        const getUsers =() => {
            if (!isLoggedIn()) {return unauthorized();}
            return ok(users.map(x => basicDetails(x)));
        };

        const getUserById =() => {
            if (!isLoggedIn()) {return unauthorized();}

            const user = users.find(x => x.id === idFromUrl());
            return ok(basicDetails(user));
        };

        const updateUser = () => ok(JSON.stringify({msg: 'Successfully updated user'}));

        const deleteUser =() => {
            if (!isLoggedIn()) {return unauthorized();}

            users = users.filter(x => x.id !== idFromUrl());
            localStorage.setItem(usersKey, JSON.stringify(users));
            return ok();
        };

        const handleRoute= () => {
            if (!url.includes('fake')) {
                return next.handle(request);
            }
            switch (true) {
                case url.endsWith('/person/authenticate') && method === 'POST':
                    return authenticate();
                case url.endsWith('/person/register') && method === 'POST':
                    return register();
                case url.endsWith('/person') && method === 'GET':
                    return getUsers();
                case url.match(/\/person\/\d+$/) && method === 'GET':
                    return getUserById();
                case url.match(/\/person\/\d+$/) && method === 'PUT':
                    return updateUser();
                case url.match(/\/person\/\d+$/) && method === 'DELETE':
                    return deleteUser();
                default:
                    // pass through any requests not handled above
                    return next.handle(request);
            }
        };
        return handleRoute();

    }
}

export const fakeBackendProvider = {
    // use fake backend in place of Http service for backend-less development
    provide: HTTP_INTERCEPTORS,
    useClass: FakeBackendInterceptor,
    multi: true
};
