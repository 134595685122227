<div class="sparks-content">
    <app-lbar></app-lbar>
    <div class="content">
        <section class="welcome">
            <h1 class="mat-h1">Salt Management</h1>
            <mat-list class="tour">
                <mat-list-item *ngIf=false>
                    <mat-icon>done</mat-icon>Manage the different components of you AllSpark Account
                </mat-list-item>
                <mat-list-item *ngIf=false>
                    <mat-icon>done</mat-icon>Manage the email or username of your accounts
                </mat-list-item>
                <mat-list-item *ngIf=false>
                    <mat-icon>done</mat-icon>Manage which URLs map to different service
                </mat-list-item>
                <mat-list-item>
                    <mat-icon>done</mat-icon>Manage the unique salts created for each service acount
                </mat-list-item>
                <mat-list-item>
                    <mat-icon>information</mat-icon>For more information visit our docs
                </mat-list-item>
            </mat-list>
        </section>
        <div *ngIf="!newUser">
            <section class="spark-table">
                <div class="spark-table-forms">
                    <button mat-button class="add-spark" (click)="openCreateDialog()">
                        <mat-icon> add</mat-icon>
                        Add Spark
                    </button>
                    <mat-form-field appearance="fill">
                        <mat-label>Search for the website you want to manage</mat-label>
                        <input matInput (keyup)="applyFilter($event)" #filterInput>
                        <mat-icon matSuffix color="primary">search</mat-icon>
                    </mat-form-field>
                </div>
                <table mat-table multiTemplateDataRows [dataSource]="allSparkDataSource" matSort
                    class="mat-elevation-z2">
                    <ng-container matColumnDef="id">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> ID </th>
                        <td mat-cell *matCellDef="let element"> {{element.id}} </td>
                    </ng-container>
                    <ng-container matColumnDef="service">
                        <th mat-header-cell *matHeaderCellDef> service </th>
                        <td mat-cell *matCellDef="let element"> {{element.serviceName}} </td>
                    </ng-container>
                    <ng-container matColumnDef="serviceUrls">
                        <th mat-header-cell *matHeaderCellDef> serviceUrls </th>
                        <td mat-cell class="no-wrap-cell" *matCellDef="let element">
                            {{element.serviceUrls}}
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="actions">
                        <th mat-header-cell *matHeaderCellDef> Actions</th>
                        <td mat-cell class="actions-cell" *matCellDef="let element">
                            <button mat-button class="action-btns" matTooltip="Reset Salt">
                                <mat-icon>update</mat-icon>
                            </button>
                            <button mat-button class="action-btns" matTooltip="Modify Salt">
                                <mat-icon>edit</mat-icon>
                            </button>
                            <button mat-button class="action-btns" matTooltip="Delete Salt">
                                <mat-icon>delete</mat-icon>
                            </button>
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="accountDetails">
                        <td mat-cell *matCellDef="let row" [attr.colspan]="displayedColumns.length">
                            <div [ngClass]="(selection.isSelected(row)) ? 'detail-row-expanded': 'detail-row-element'">
                                <app-spark-detail-row [sparkEntry]="row"> </app-spark-detail-row>
                            </div>
                        </td>
                    </ng-container>
                    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true;" (click)="onRowClicked">
                    </tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;" (click)="selection.toggle(row)"
                        [ngClass]="{highlighted: selection.isSelected(row)}"></tr>
                    <tr mat-row class="detail-row" *matRowDef="let row; columns: ['accountDetails']"></tr>
                    <!-- Row shown when there is no matching data.
                        <ng-container matColumnDef="account">
                <th mat-header-cell *matHeaderCellDef> Account </th>
                <td mat-cell *matCellDef="let element"> {{element.account.account}} </td>
            </ng-container>
            <ng-container matColumnDef="salt">
                <th mat-header-cell *matHeaderCellDef> accountSalt </th>
                <td mat-cell class="no-wrap-cell" *matCellDef="let element">
                    {{element.account.accountSalt}}
                </td>
            </ng-container>
            -->
                    <tr class="mat-row" *matNoDataRow>
                        <td class="mat-cell" colspan="4">No data matching the filter "{{filterInput.value}}"</td>
                    </tr>
                </table>
            </section>
            <section class="details" appSparkDetail>
                <app-spark-detail *ngFor="let selectedSparkEntry of selection.selected"
                    [sparkEntry]="selectedSparkEntry"></app-spark-detail>
            </section>
        </div>

    </div>
</div>