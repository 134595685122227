import { Component, OnInit, Input } from '@angular/core';
import { FESparkEntry } from '../spark.model';

@Component({
  selector: 'app-spark-detail',
  templateUrl: './spark-detail.component.html',
  styleUrls: ['./spark-detail.component.scss'],
})
export class SparkDetailComponent implements OnInit {
  @Input() sparkEntry: FESparkEntry;

  constructor() {}

  ngOnInit(): void {}
}
