<div class="lbar">
    <h2 class="mat-h1">Settings</h2>
    <mat-list dense class="mat-typography">
        <hr />
        <mat-list-item>
            <mat-icon color="primary">person</mat-icon>
            <a mat-list-item href="#/account">Manage Account</a>
        </mat-list-item>
        <!-- <mat-list-item>
        <mat-icon color="primary">public</mat-icon>
        <a mat-list-item href="/sparks#">Manage Services</a>
    </mat-list-item> -->
        <mat-list-item>
            <mat-icon color="primary">ac_unit</mat-icon>
            <a mat-list-item href="#/sparks">Manage Salts</a>
        </mat-list-item>
    </mat-list>
</div>