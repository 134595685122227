import { Component, OnInit } from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import { LoginComponent } from 'src/app/atoms/login/login.component';
import { SignupComponent } from 'src/app/atoms/signup/signup.component';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  constructor(public dialog: MatDialog  ){ }

  ngOnInit(): void {  }

  openDialog(): void {
    const dialogRef = this.dialog.open(LoginComponent, {data:2});
    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
      console.log(result); // Pizza!
    });

  }
}
