<div *ngIf="sparkEntry">
<mat-card>
<section class="user-detail">
    <h1> User is :  YOLO </h1>
    <p> The current user salt is: YOLOVALUE</p>
    <p>Selected Entry ID: {{sparkEntry.id}}</p>
    <hr>
</section>
<section class="service-detail">
    <div class="servcice-wrapper">
        <h1>Service Section</h1>
        <p> service Name is: {{sparkEntry.serviceName}}</p>
        <p> service ID: {{sparkEntry.id}}</p>
        <p> service URLS: {{sparkEntry.serviceUrls}}</p>
    </div>
</section>
<section class="account-detail">
    <div class="account-wrapper">
        <h1>Account Section</h1>
        <div *ngFor="let entry of sparkEntry.accounts">
            <hr>
            <p> Login: {{entry.account}}</p>
            <p> Account Salt: {{entry.accountSalt}}</p>
            <p> AccountId: {{entry.id}}</p>
            <p> ServiceId: {{entry.serviceEntryId}}</p>
        </div>
    </div>
</section>
</mat-card>
</div>
