<mat-card>
<section>
    <div class="servcice-wrapper">
        <p> Service URLS: {{sparkEntry.serviceUrls}}</p>
    </div>
    <div class="account-wrapper">
        <h2>Accounts Associated with Service</h2>
        <hr>
        <mat-list>
            <mat-card  *ngFor="let entry of sparkEntry.accounts" class="tile-content">
                <p> Login: {{entry.account}}</p>
                <p class="salt-value"> Account Salt: {{entry.accountSalt}}</p>
                <p> AccountId: {{entry.id}}</p>
                <p> ServiceId: {{entry.serviceEntryId}}</p>
            </mat-card>
        </mat-list>
    </div>
</section>
</mat-card>
