import {  StepperSelectionEvent} from '@angular/cdk/stepper';
import { Component, OnInit, ViewChild } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatStepper } from '@angular/material/stepper';
import { LoginComponent } from 'src/app/atoms/login/login.component';
import { AuthService } from 'src/app/shared/auth.service';
import { SparkService } from '../sparks/spark.service';

@Component({
  selector: 'app-transformer',
  templateUrl: './transformer.component.html',
  styleUrls: ['./transformer.component.scss'],
})
export class TransformerComponent implements OnInit {
  @ViewChild('stepper') private myStepper: MatStepper;

  hashedPwdInfo = 'Offline operation will transform your memorable password';
  enableHashing = false;
  pShow = false;
  serviceNotFound = false;
  firstUse = false;
  transformerForm: FormGroup = this.fb.group({
    // serviceName: new FormControl({value: '', disabled: false}, [Validators.required]),
    websiteUrl: new FormControl('', [Validators.required]),
    entropyInput: new FormControl('', []),
    entropy: new FormControl({ value: this.hashedPwdInfo, disabled: true }, [
      Validators.required,
    ]),
    account: new FormControl('', [Validators.required]),
    pwd: new FormControl('', [Validators.required]),
    hashedPwd: new FormControl({ value: '', disabled: true }, [
      Validators.required,
    ]),
  });

  constructor(
    private fb: FormBuilder,
    private authService: AuthService,
    private dialog: MatDialog,
    private sparkService: SparkService
  ) { }

  ngOnInit(): void { }

  get f() {
    return this.transformerForm.controls;
  }
  get websiteUrl(){
    return this.f.websiteUrl.value;
  }
  setCreatedEntropy(createdSpark: string){
    this.f.entropy.setValue(createdSpark);
  }
  get isLoggedIn() {
    return this.authService.isLoggedIn;
  }
  selectionChange(e: StepperSelectionEvent): void {
    if (e.selectedIndex === 0) {
      this.firstUse = false;
      this.serviceNotFound = false;
    }
  }
  onLoadClick() {
    if (this.f.websiteUrl.value === '') {
      this.f.websiteUrl.setErrors({ require: true });
      return;
    }
    this.sparkService.getAccountSpark(this.f.websiteUrl.value).subscribe(
      (entropy) => {
        this.f.entropy.setValue(entropy);
        this.enableHashing = true;
        this.onPKeyUp();
        this.myStepper.next();
        this.firstUse = false;
        this.serviceNotFound = false;
      },
      (error) => {
        console.log(error);
        // TODO: use wire protocol and enums between BE<->FE
        if (error.error.search('ServiceNotFound:') !== -1) {
          this.serviceNotFound = true;
        } else if (error.error.search('Onboard:') !== -1) {
          this.firstUse = true;
        }
        if(this.myStepper.selectedIndex === 0) {
          // Reload on selection failed
          this.myStepper.next();
        }
      }
    );
  }
  togglePShow() {
    this.pShow = !this.pShow;
  }
  navigateToService(): void {
    window.open(this.websiteUrl)   ;
  }
  generateServiceSpark() {
    const createSparkRequest = {serviceUrl: this.f.websiteUrl.value, account :''};
    this.sparkService
      .createSpark(createSparkRequest)
      .subscribe((response) => {
        console.log('Create spark response:', response);
        this.onLoadClick();
      });
    alert('Added new salt' + this.websiteUrl);
  }
  openLoginDialog(): void {
    const dialogRef = this.dialog.open(LoginComponent);
    dialogRef.afterClosed().subscribe((result) => {
      console.log('The dialog was closed');
      console.log(result); // Pizza!
      if (result === true) {
        this.authService.isLoggedIn = true;
      }
    });
  }
  onPKeyUp() {
    if (this.enableHashing) {
      const pvalue = this.f.pwd.value;
      this.sparkService
        .sparkIt(this.f.entropy.value, this.f.account.value, pvalue)
        .then((sparkedValue) => {
          this.f.hashedPwd.setValue(sparkedValue);
        });
    }
  }
}
