import { Component, Input, OnInit } from '@angular/core';
import { FESparkEntry } from '../spark.model';


export interface Tile {
  color: string;
  cols: number;
  rows: number;
  text: string;
}


@Component({
  selector: 'app-spark-detail-row',
  templateUrl: './spark-detail-row.component.html',
  styleUrls: ['./spark-detail-row.component.scss']
})
export class SparkDetailRowComponent implements OnInit {
  @Input() sparkEntry: FESparkEntry;

  tiles: Tile[] = [
    { text: 'One', cols: 3, rows: 1, color: 'lightblue' },
    { text: 'Two', cols: 1, rows: 2, color: 'lightgreen' },
    { text: 'Three', cols: 1, rows: 1, color: 'lightpink' },
    { text: 'Four', cols: 2, rows: 1, color: '#DDBDF1' },
  ];


  constructor() { }

  ngOnInit(): void {
  }

}
