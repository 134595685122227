<div class="transformer-content" fxLayout="column" fxLayoutAlign="center center">
    <mat-card>
        <mat-card-header class="mat-h1"> Password Transformer </mat-card-header>
        <mat-divider [inset]="true"></mat-divider>
        <mat-card-content>
            <form class="transformer-form" fxLayout="column" [formGroup]="transformerForm">
                <mat-stepper orientation="vertical" linear #stepper selectedIndex="index"
                    (selectionChange)="selectionChange($event)">
                    <mat-step *ngIf="isLoggedIn" label="Load the transformation seed from your AllSpark account">
                        <!-- <mat-form-field fxFill>
                            <label for="websiteUrl">By Service Name:</label>
                            <input matInput class="long-form-field" id="serviceName" formControlName="serviceName">
                        </mat-form-field> -->
                        <mat-form-field fxFill>
                            <mat-label for="websiteUrl">Website Url of the Service:</mat-label>
                            <input matInput class="long-form-field" type="text" id="websiteUrl" #webUrl
                                placeholder="Ex: http://allspark.io/login  allspark.io" formControlName="websiteUrl">
                        </mat-form-field>
                        <button class="load-button" mat-raised-button (click)="onLoadClick()">
                            Next</button>
                    </mat-step>
                    <mat-step *ngIf="!isLoggedIn" label="Runnng in Ghost Mode: Enter your transformation seed">
                        <mat-form-field fxFill>
                            <label>Self managed seed value: </label>
                            <input matInput class="long-form-field" formControlName="entropyInput" />
                            <button mat-button matSuffix aria-label="Password transformation seed" matTooltip="Provide the salt for passwod transformation, un authenticated users manage their own salts.
                                If you have an AllSpark account login to load from your profile">
                                <mat-icon>help</mat-icon>
                            </button>
                        </mat-form-field>
                        <div fxLayoutAlign="flex-start center" fxLayoutGap="20px">
                            <button class="load-button" mat-raised-button matStepperNext>
                                Next</button>
                            <p>*Login to load from your allspark account</p>
                        </div>
                    </mat-step>
                    <!-- Unrecognized URL -->
                    <mat-step *ngIf="serviceNotFound" label="The Url or Service was not recognized by AllSpark">
                        <app-create-dialog [siteUrl]="websiteUrl" (createdSparkEvent)="setCreatedEntropy($event)">
                        </app-create-dialog>
                        <button mat-raised-button (click)="onLoadClick()"> ReLoad</button>
                    </mat-step>
                    <!-- First Time -->
                    <mat-step *ngIf="firstUse" label="Onboard your serice to AllSpark">
                        <mat-label>Looks like this is the first time for the service on AllSpark</mat-label>
                        <button tabindex="-1" mat-button aria-label="Generate Salt"
                            matTooltip="The value in this field is hashed offline with your Username and password below"
                            (click)="generateServiceSpark()">
                            <mat-icon>sync</mat-icon> Generate Salt
                        </button>
                    </mat-step>
                    <!-- Offline Operation -->
                    <mat-step label="Offline transformation operation">
                        <mat-label>Seed used in transformation:</mat-label>
                        <mat-form-field>
                            <input [disabled]="true" matInput class="long-form-field" formControlName="entropy" />
                            <button tabindex="-1" mat-button matSuffix aria-label="Top level Salt"
                                matTooltip="The value in this field is hashed offline with your Username and password below">
                                <mat-icon>help</mat-icon>
                            </button>
                        </mat-form-field>
                        <p class="mat-hint no-margin">The operation below is compltely offline!
                        </p>

                        <!-- Login Info -->
                        <div fxLayout="column column" fxLayoutAlign="center">
                            <mat-form-field appearance="outline">
                                <mat-label for="account" class="account-label">Username or Email</mat-label>
                                <input matInput class="account-field" type="text" id="account"
                                    formControlName="account" />

                            </mat-form-field>
                            <mat-form-field appearance="outline">
                                <mat-label for="pwd" class="account-label">Memorable Password</mat-label>
                                <input matInput class="account-field" type="password" id="pwd" formControlName="pwd"
                                    (keyup)="onPKeyUp()">
                                <button mat-button matSuffix> </button>
                            </mat-form-field>
                        </div>
                        <!-- Output-->
                        <mat-label fxFill>Transpiled Website Password</mat-label>
                        <mat-form-field fxFill>
                            <input [type]="pShow ? 'text' : 'password'" matInput #machinepwd
                                formControlName="hashedPwd" />
                            <button tabindex="-1" mat-button matSuffix aria-label="Show Password Button">
                                <mat-icon mat-button *ngIf="pShow" (click)="togglePShow()">visibility</mat-icon>
                                <mat-icon mat-button *ngIf="!pShow" (click)="togglePShow()">visibility_off</mat-icon>
                            </button>
                            <button tabindex="-1" mat-button matSuffix aria-label="Copy Button"
                                matTooltip="Copy transformed password" [cdkCopyToClipboard]="machinepwd.value">
                                <mat-icon>content_copy</mat-icon>
                            </button>
                        </mat-form-field>

                        <button mat-raised-button matStepperNext [cdkCopyToClipboard]="machinepwd.value"
                            (click)="navigateToService()">
                            COPY & OPEN</button>
                    </mat-step>

                </mat-stepper>

            </form>
        </mat-card-content>
    </mat-card>
</div>