import { Component, Inject, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { Person, PersonService } from 'src/app/shared/person.service';

@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.scss']
})
export class SignupComponent{
  minLength = 8;
  loading = false;
  submitted = false;
  errorMsg = '';

  registerForm: FormGroup = this.fb.group({
    firstName: new FormControl('', Validators.required),
    lastName: new FormControl('', Validators.required),
    email: new FormControl('', [Validators.required, Validators.email]),
    password: new FormControl('', [Validators.required, Validators.minLength(this.minLength)])
  });

  constructor(private fb: FormBuilder, public dialogRef: MatDialogRef<SignupComponent>,
     private personService: PersonService) {
    }
    onDebug(){
      console.log('debug');
    }
    // convenience getter for easy access to form fields
    get f() {
      return this.registerForm.controls;
    }

    closeDialog(): void {
      this.dialogRef.close();
    }

    onSubmit(): void {
        this.submitted = true;
        // stop here if form is invalid
        if (this.registerForm.invalid) {
            return;
        }
        const newUser: Person = this.registerForm.value;
        this.personService.singup(newUser).subscribe(response=> {
          console.log(response);
          this.dialogRef.close(newUser);
        }, error => {
            this.errorMsg = error;
            this.f.email.setErrors({notUnique: true});
        });
    }
}
